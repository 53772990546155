#securitydata {
  background: #ffffff;
  padding: 20px 0;
}

.securitydata h6 {
  font-size: 20px;
  font-weight: 400;
  color: var(--black);
}

.securitydata p {
  margin-top: 10px;
  padding-left: 20px;
  background-color: #d9edf7;
  border: 1px solid #bce8f1;
  color: #31708f;
  border-color: #bce8f1;
  font-size: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #767577;
  border-radius: 24px;
  transition: 0.4s;
  width: 50px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

.slider-on {
  background-color: #4caf50;
}

.slider-on:before {
  transform: translateX(24px);
}

.slider-off {
  background-color: #767577;
}

.switch_block {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.switch_block h5 {
  background: none;
  font-size: 14px;
  font-weight: 400;
  color: #2b2b2b;
}

.switch_block h5 span {
  color: black;
  font-weight: 600;
}
/* ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,, */

.switch_blocks {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.input_block {
  margin-top: 20px;
  width: 100%;
  border: 2px solid #d9edf7;
  border-radius: 10px;
  min-height: 100px;
  padding: 10px;
}

@keyframes input_block {
  0% {
    max-height: 0;
    opacity: 0;
    transform: scaleY(0.9);
  }
  100% {
    max-height: 500px; /* форманын максималдуу бийиктигин коюңуз */
    opacity: 1;
    transform: scaleY(1);
  }
}

.form-container {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transform: scaleY(0.9);
  animation: input_block 0.1s ease-in-out forwards;
}

.input-text {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0;
  background: #b2f7c6;
  padding-right: 20px;
}

.input-text a {
  text-decoration: none;
  font-size: 16px;
  color: #31708f;
}

.input-dany {
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 10px;
}

.input-dany input {
  outline: none;
  padding: 10px 0 10px 10px;
  border-radius: 5px;
  border: 1px solid #9e9f9f;
}

.input-data {
  padding-top: 5px;
  flex-wrap: wrap;
  width: 100%;
  display: grid;
}

.input-data input {
  margin: 5px 0;
  outline: none;
  padding: 10px 0 10px 10px;
  border-radius: 5px;
  border: 1px solid #9e9f9f;
}

.input-text-p {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-text-p p {
  width: 800px;
}

.input-info {
  padding-top: 20px;
}

.input-info h5 {
  font-weight: 400;
  font-size: 20px;
  padding-left: 10px;
  padding-top: 30px;
}

.input-info textarea {
  margin-top: 10px;
  padding: 20px 0 0 15px;
  outline: none;
  width: 100%;
  min-height: 150px;
  border-radius: 5px;
}

.check-texts {
  padding-top: 40px;
  display: flex;
  align-items: center;
  gap: 30px;
}

.check-tx h4 {
  font-weight: 400;
  font-size: 18px;
}

.check-tx h6 {
  font-size: 14px;
  padding-top: 5px;
}

.check-txs h4 {
  font-weight: 400;
  font-size: 18px;
}

.check-txs h6 {
  padding-top: 5px;
  font-size: 14px;
}

.check-namber {
  display: flex;
  align-items: center;
  gap: 5px;
}

.check-t {
  display: flex;
  align-items: center;
  gap: 5px;
}

.check-namber {
  padding-top: 20px;
}

.check-t {
  padding-top: 20px;
}

.check-namber input {
  outline: none;
  width: 15px;
  height: 15px;
  border: 1px solid #9e9f9f;
}

.input-button button:disabled {
  background-color: #ddd; 
  color: #aaa;
  cursor: not-allowed; 
}
.check-t input {
  outline: none;
  width: 15px;
  height: 15px;
  border: 1px solid #9e9f9f;
}

.input-button {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30%;
}

.input-button button {
  padding: 15px 25px;
  border-radius: 5px;
  border: none;
  background: #473fb2;
  text-decoration: none;
  color: #ffffff;
  font-size: 18px;
}

.input-button button {
  text-decoration: none;
  color: #ffffff;
  font-size: 18px;
}

.input-line {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2px;
  margin-top: 50px;
  background: #9e9f9f;
}

.check-namber {
  display: flex;
  align-items: center;
  gap: 20px;
}

.check-ts {
  display: flex;
  align-items: center;
  gap: 10px;
}

.check-t {
  display: flex;
  align-items: center;
  gap: 20px;
}

.check-tt {
  display: flex;
  align-items: center;
  gap: 10px;
}

.alert-success {
  position: fixed;
  top: 90px;
  right: 20px;
  background-color: #ffffff;
  color: #2b2b2b;
  padding: 15px 20px;
  border-radius: 8px;
  z-index: 9999;
  display: flex;
  align-items: center;
  gap: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-left: 4px solid #4caf50;
  min-width: 300px;
  animation: slideIn 0.3s ease-out;
}

.alert-icon {
  background-color: #4caf50;
  color: white;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.alert-content {
  flex: 1;
}

.alert-title {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 4px;
}

.alert-message {
  font-size: 14px;
  color: #666;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Добавим анимацию выхода */
.alert-success.hiding {
  animation: slideOut 0.3s ease-in forwards;
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

@media (max-width: 600px) {
  .securitydata h6 {
    font-size: 16px;
  }
  .slider {
    width: 40px;
    height: 20px;
  }
  .slider:before {
    width: 15px;
    height: 15px;
  }
  .securitydata p {
    min-height: 140px;
    font-size: 14px;
    padding: 20px 10px 20px 10px;
  }
  .input-info h5 {
    padding-top: 20px;
  }
  .input-info {
    padding-top: 0px;
  }

  .switch_block h5 {
    font-size: 14px;
  }
  .switch_blocks h5 {
    font-size: 14px;
  }
  .slider-on:before {
    transform: translateX(15px);
  }
  .input-text {
    padding: 10px;
  }
  .input-text a {
    font-size: 12px;
  }
  .input-dany input::placeholder {
    font-size: 12px;
  }
  .input-data input::placeholder {
    font-size: 12px;
  }
  .input-info h5 {
    font-size: 16px;
  }
  .textarea {
    min-height: 80px;
  }
  .check-tx h4 {
    font-size: 16px;
  }
  .check-txs h6 {
    font-size: 12px;
  }
  .check-namber {
    font-size: 12px;
    gap: 5px;
  }
  .check-txs h4 {
    font-size: 16px;
  }
  .check-ts h6 {
    font-size: 12px;
  }
  .check-t {
    font-size: 12px;
    gap: 5px;
  }
  .input-button button {
    padding: 10px 20px;
    font-size: 14px;
  }
}

@media (max-width: 530px) {
  .switch_block h5 {
    font-size: 10px;
    font-weight: 400;
  }

}

.alert-error {
  border-left: 4px solid #f44336;
}

.alert-icon-error {
  background-color: #f44336;
}

.alert-error .alert-title {
  color: #f4372a;
}
@media (max-width: 440px) {
  .check-texts {
    display: flex;
    flex-direction: column;
  }
  .input-dany {
    padding-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
  }
  /* .input-data {
    grid-template-columns: 1fr;
  } */
  .cap {
    padding-top: 40px;
  }
}



