.main {
    background: #fff;
    padding-bottom: 150px;
}

.main h2 {
    width: 100%;
    font-size: 30px;
    font-weight: 400;
    color: var(--black);
    text-align: center;
}

@media (max-width: 600px) {
    .main h2 {
        width: 100%;
        font-size: 22px;
        font-weight: 400;
        color: var(--black);
        text-align: start;
    }
}