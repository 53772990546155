.we_partner {
    margin-top: 130px;
    width: 100%;
}

.we_partner h2 {
    text-align: center;
    margin-bottom: 40px;
}

.we_partner .slide_image {
    margin: 0 auto;
    width: 90%;
    height: 350px;
    padding: 24px;
    background: var(--grayW);
    border-radius: 30px;
}

.we_partner .slide_image .img {
    width: 100%;
    height: 200px;
    background: #fff;
    border-radius: 30px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.we_partner .slide_image .img img {
    width: 100%;
    height: 200px;
    border-radius: 30px;
    object-fit: contain;
    object-position: center;
}

.we_partner .title {
    font-size: 20px;
    font-weight: 400;
    color: var(--black);
}

.we_partner .block_block {
    display: none;
}

@media (max-width: 600px) {

    .we_partner .slide_block {
        display: none;
    }

    .we_partner .block_block {
        display: flex;
        overflow-y: hidden;
        gap: 10px;
    }

    .we_partner {
        margin-top: 50px;
        width: 100%;
    }

    .we_partner h2 {
        text-align: start;
        margin-bottom: 16px;
    }

    .we_partner .slide_image {
        margin: 0;
        width: 130px;
        height: 100%;
        padding: 8px;
        background: var(--grayW);
        border-radius: 14px;
        box-sizing: border-box;
    }

    .we_partner .slide_image .img {
        margin-bottom: 0;
        height: 90px;
    }

    .we_partner .slide_image .img img {
        width: 100%;
        height: 90px;
        border-radius: 12px;
    }

    .we_partner .title {
        margin-top: 10px;
        font-size: 12px;
        font-weight: 300;
        color: var(--black);
    }
}