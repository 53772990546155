/* Modal */

.fixet_modal_down {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    z-index: 999999;
    animation: modal .3s ease-in-out;
}

@keyframes modal {
    0% {
        bottom: -400px;
    }

    100% {
        bottom: 0;
    }
}

.fixet_modal_down .modal_down_not {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.20);
    animation: focus .5s ease-in;
}

@keyframes focus {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.fixet_modal_down .modal_down {
    position: absolute;
    padding: 10px 16px 30px 16px;
    border-radius: 24px 24px 0px 0px;
    border: 1px solid var(--2, #E8E8E8);
    box-shadow: 0px 1px 9.4px 0px rgba(222, 222, 222, 0.10), 0px 5px 20px 0px rgba(194, 194, 194, 0.09), 0px 10px 6px 0px rgba(221, 221, 224, 0.05), 0px 19px 7px 0px rgba(134, 134, 134, 0.01), 0px 29px 8px 0px rgba(0, 0, 0, 0.00);
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--white);
    width: 100%;
}

.fixet_modal_down .icon {
    position: absolute;
}

.fixet_modal_down .modal_down img {
    width: 40px;
    height: 40px;
}

.fixet_modal_down .modal_title {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 400;
    color: var(--black);
}

.fixet_modal_down .modal_text {
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 300;
    color: var(--gray);
}

.fixet_modal_down .modal_btn {
    margin-top: 10px;
    width: 100%;
    height: 45px;
    padding: 10px 20px;
    background: var(--blue);
    border-radius: 12px;
    color: var(--white);
    font-size: 15px;
    font-weight: 400;
    outline: none;
    border: none;
    cursor: pointer;
}