.ticket {
    padding-top: 40px;
    width: 100%;
    padding-bottom: 250px;
}

.ticket .search_block {
    width: 80%;
    display: flex;
    gap: 5px;
    padding: 30px;
    border-radius: 22px;
    background: var(--white);
    box-shadow: 0px 4px 8px 0px rgba(180, 180, 180, 0.07), 0px 14px 14px 0px rgba(156, 156, 156, 0.06), 0px 33px 20px 0px rgba(173, 173, 173, 0.05);
}

.ticket .search_div {
    display: flex;
    gap: 5px;
}

.ticket .border1 {
    border-radius: 12px 0 0 12px;
}

.ticket .border2 {
    border-radius: 0 12px 12px 0;
}

.ticket input {
    padding: 0 15px;
    width: 100%;
    height: 50px;
    font-size: 16px;
    font-weight: 300;
    background: #F5F7FA;
    outline: none;
    border: none;
}

.ticket input::placeholder {
    color: #9F9F9F;
}

.ticket button {
    padding: 10px 30px;
    background: var(--blue);
    border-radius: 12px;
    color: var(--white);
    font-size: 15px;
    font-weight: 400;
    outline: none;
    border: none;
    cursor: pointer;
}

.ticket .contant {
    width: 60%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.ticket .flex {
    display: flex;
    flex-direction: column;
    gap: 60px;
}

.ticket .air {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ticket .ticket_box {
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 15px;
    padding: 30px;
    border-radius: 30px;
    background: var(--white);
    box-shadow: 0px 1px 9.4px 0px rgba(222, 222, 222, 0.10), 0px 5px 20px 0px rgba(194, 194, 194, 0.09), 0px 10px 6px 0px rgba(221, 221, 224, 0.05), 0px 19px 7px 0px rgba(134, 134, 134, 0.01), 0px 29px 8px 0px rgba(0, 0, 0, 0.00);
}

.ticket .air_contant {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ticket .air_and_line {
    display: flex;
    align-items: center;
}

.ticket .summa {
    border-left: 1px solid var(--3, #DBDBDB);
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.ticket .summa button {
    margin-top: 30px;
    width: 100%;
    height: 45px;
}

.ticket .summa .btn {
    height: 30px;
}

.ticket .ticket_box .title {
    font-size: 24px;
    font-weight: 400;
    color: var(--black);
}

.ticket .ticket_box p {
    text-align: center;
    font-size: 14px;
    font-weight: 300;
    color: var(--gray);
}

.ticket .ticket_box .price {
    display: flex;
    justify-content: center;
    font-size: 24px;
    font-weight: 400;
    color: var(--blue);
}

.ticket .summs_block,
.ticket .navigate_block {
    display: none;
}

@media (max-width: 600px) {
    .ticket {
        padding-top: 20px;
        padding-bottom: 150px;
    }

    .ticket .search_block {
        display: none;
    }

    .ticket .navigate_block {
        display: grid;
        grid-template-columns: 40px 1fr 40px;
        align-items: center;
    }

    .ticket .navigate_block div {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .ticket .navigate_title {
        font-size: 18px;
        font-weight: 400;
        color: var(--black);
    }

    .ticket .navigate_text {
        font-size: 14px;
        font-weight: 400;
        color: var(--gray);
    }

    .ticket .contant {
        width: 100%;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .ticket .flex {
        display: flex;
        flex-direction: column;
        gap: 60px;
    }

    .ticket .air {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .ticket .air.top {
        border-top: 1px solid var(--grayW);
        padding-top: 16px;
    }

    .ticket .ticket_box {
        display: grid;
        grid-template-columns: 1fr;
        gap: 15px;
        padding: 16px;
        border-radius: 20px;
        background: var(--white);
    }

    .ticket .air_contant {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .ticket .air_and_line {
        display: flex;
        align-items: center;
    }

    .ticket .air_and_line .line {
        width: 50px;
    }

    .ticket .summa {
        display: none;
    }

    .ticket .summs_block {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .ticket .summa button {
        margin-top: 30px;
        width: 100%;
        height: 45px;
        border-radius: 10px;
    }

    .ticket .summa .btn {
        height: 30px;
    }

    .ticket .ticket_box .title {
        font-size: 16px;
        font-weight: 400;
        color: var(--black);
    }

    .ticket .ticket_box p {
        text-align: center;
        font-size: 12px;
        font-weight: 300;
        color: var(--gray);
    }

    .ticket .ticket_box .price {
        display: flex;
        justify-content: flex-start;
        font-size: 18px;
        font-weight: 400;
        color: var(--blue);
    }
}