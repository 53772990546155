.footer {
  width: 100%;
  background: var(--blue);
  padding: 40px 0 20px 0;
}

.footer .logo_image {
  width: 140px;
  height: 50px;
}

.footer .logo {
  text-decoration: none;
  font-size: 30px;
  font-weight: 600;
  color: var(--white);
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}

.footer .foot {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 25px;
}

.footer h4 {
  font-size: 20px;
  font-weight: 500;
  color: var(--white);
  margin-bottom: 10px;
}

.footer .foot_text {
  display: block;
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  text-decoration: none;
  transition: 0.3s ease;
  position: relative;
  padding-left: 15px;
}

.footer .foot_text::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 5px;
  height: 1px;
  background-color: #fff;
}

p {
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  transition: 0.3s ease;
}

.footer .foot_text:hover {
  padding-left: 20px;
}

.footer .foot_div {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.footer .foot_div a {
  text-decoration: none;
}

.footer .icons {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: row !important;
  gap: 15px;
}

.footer .icons .icon {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white);
}

.footer .all {
  color: #fff;
  font-size: 12px;
}

.footer .footer-flex {
  margin-top: 50px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer .navis-dev{
  color: var(--orange);
  text-decoration: underline;
}

@media (max-width: 600px) {
  .footer .foot {
    display: flex;
    flex-direction: column;
    gap: 45px;
  }

  .footer .logo {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 14px;
  }

  .footer .icons {
    margin-top: 14px;
  }

  .footer h4 {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 4px;
  }

  .footer .foot_div {
    gap: 8px;
  }
}
