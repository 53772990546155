.directions {
  margin-top: 100px;
  width: 100%;
}

.directions .current_head {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.directions .direct {
  top: 0;
  right: 0;
  position: absolute;
  display: flex;
  justify-content: flex-end;
}

.directions .directions_contant {
  margin-top: 50px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}

.directions .direct {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  width: 400px;
  text-decoration: none;
  color: var(--gray);
  cursor: pointer;
}

.directions .direct:hover {
  color: var(--blue);
}

.directions .directions_box {
  width: 100%;
  height: 300px;
  border-radius: 24px;
  position: relative;
}

.directions .city {
  position: absolute;
  top: 15px;
  left: 15px;
  min-width: 0px;
  font-size: 16px;
  font-weight: 400;
  color: var(--black);
  padding: 10px 20px;
  background: var(--white);
  border-radius: 12px;
  z-index: 2;
}

.directions .directions_box img {
  cursor: pointer;
  border-radius: 24px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 1;
}

@media (max-width: 600px) {
  .directions {
    margin-top: 50px;
  }

  .directions .directions_contant {
    margin-top: 16px;
    display: grid;
    overflow-y: hidden;
    gap: 10px;
  }

  .directions .directions_box {
    width: 200px;
    height: 200px;
    padding: 14px;
    box-sizing: border-box;
  }

  .directions .city {
    margin: 0;
    width: 90px;
    font-size: 14px;
    font-weight: 400;
    color: var(--black);
    padding: 10px;
  }

  .directions .direct span {
    display: none;
  }

  .directions .direct {
    top: -5px;
    font-size: 14px;
  }

  .directions .direct {
    width: 30px;
    height: 30px;
  }

  .directions .current_head img {
    width: 30px;
    height: 30px;
  }
}
