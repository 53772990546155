.select {
  position: absolute;
  top: 4px;
  left: 0;
  width: 100%;
  border-radius: 16px;
  background: var(--white);
  box-shadow: 5px 5px 25px rgba(222, 222, 222, 0.5),
    0px 5px 20px 0px rgba(194, 194, 194, 0.09),
    0px 10px 6px 0px rgba(61, 61, 63, 0.05),
    0px 19px 7px 0px rgba(56, 55, 55, 0.01), 0px 29px 8px 0px rgba(0, 0, 0, 0);
  border: 1px solid #e8e8e8;
  z-index: 999999999;
  animation: select 0.4s ease;
}

.not_found_data {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
}

@keyframes select {
  0% {
    opacity: 0;
    top: 80px;
  }
  100% {
    opacity: 1;
    top: 4px;
  }
}

.select .select_div {
  width: 100%;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  gap: 15px;
  transition: 0.2s ease;
  cursor: pointer;
}

.select .select_div:first-child {
  border-radius: 16px 16px 0 0;
}

.select .select_div:last-child {
  border-radius: 0 0 16px 16px;
}

.select .select_div:only-child {
  border-radius: 16px;
}

.select .select_div:hover {
  background: #d4dde63d;
}

.select .select_div img {
  width: 50px;
  height: 35px;
  border-radius: 8px;
  object-fit: cover;
  object-position: center;
}

.select .flex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select .flex_box {
  display: flex;
  align-items: center;
  gap: 4px;
}

.select .select_div .title {
  color: black;
  font-size: 16px;
  font-weight: 400;
}

.select .select_div .text {
  color: #9f9f9f;
  font-size: 16px;
  font-weight: 400;
}

.select .select_div .text.big {
  color: #9f9f9f;
  font-size: 16px;
  font-weight: 400;
}

.select .block {
  width: 100%;
}

.select .block {
  width: 100%;
  padding: 10px 16px;
}

.select .block p {
  color: #9f9f9f;
  font-size: 15px;
  font-weight: 400;
}
