.about_direct {
  margin-top: 130px;
  width: 100%;
}

.about_direct h2 {
  text-align: center;
  margin-bottom: 40px;
}

.about_direct .current_head {
  width: 100%;
  display: flex;
  position: relative;
}

.about_direct .direct {
  top: 0;
  right: 0;
  position: absolute;
  display: flex;
  justify-content: flex-end;
}

.about_direct .directions_contant {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}

.about_direct .direct {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  width: 400px;
  text-decoration: none;
  color: var(--gray);
  cursor: pointer;
}

.about_direct .direct:hover {
  color: var(--blue);
}

.about_direct .directions_box {
  width: 100%;
  height: 300px;
  border-radius: 24px;
  position: relative;
  background: url(../../img/Variant2.svg) no-repeat center / cover;
}

.about_direct .city {
  position: absolute;
  top: 15px;
  left: 15px;
  min-width: 0px;
  font-size: 16px;
  font-weight: 400;
  color: var(--black);
  padding: 10px 20px;
  background: var(--white);
  border-radius: 12px;
  z-index: 2;
}

.about_direct .directions_box img {
  cursor: pointer;
  border-radius: 24px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 1;
}

@media (max-width: 600px) {
  .about_direct {
    margin-top: 50px;
    width: 100%;
  }

  .about_direct h2 {
    text-align: start;
    margin-bottom: 16px;
  }

  .about_direct .current_head {
    width: 100%;
    display: flex;
    position: relative;
  }

  .about_direct .direct {
    top: 0;
    right: 0;
    position: absolute;
    display: flex;
    justify-content: flex-end;
  }

  .about_direct .directions_contant {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .about_direct .direct {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 20px;
    width: 400px;
    text-decoration: none;
    color: var(--gray);
    cursor: pointer;
  }

  .about_direct .direct:hover {
    color: var(--blue);
  }

  .about_direct .directions_box {
    width: 100%;
    height: 180px;
    border-radius: 20px;
    position: relative;
    background: url(../../img/Variant2.svg) no-repeat center / cover;
  }

  .about_direct .city {
    width: 80px;
    font-size: 12px;
    font-weight: 400;
    color: var(--black);
    padding: 10px;
    background: var(--white);
    border-radius: 8px;
  }
}
