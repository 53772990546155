.about_contant {
    margin-top: 130px;
    width: 100%;
}

.about_contant .contant {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 100px;
}

.about_contant .contant img {
    width: 100%;
    height: 400px;
    border-radius: 30px;
    object-fit: cover;
    object-position: bottom;
    
}

.about_contant .contant p {
    margin-top: 10px;
    font-size: 18px;
    font-weight: 300;
    color: var(--black);
}

.about_contant .block {
    display: none;
}

@media (max-width: 600px) {
    .about_contant {
        margin-top: 30px;
        width: 100%;
    }

    .about_contant .contant {
        display: grid;
        grid-template-columns: 1fr;
        gap: 16px;
    }

    .about_contant .contant img {
        height: 200px;
        border-radius: 20px;
    }

    .about_contant .contant p {
        margin-top: 0;
        font-size: 14px;
    }

    .about_contant .block {
        display: block;
    }

    .about_contant .none {
        display: none;
    }
}