.contact {
    padding-top: 40px;
    width: 100%;
    background: var(--white);
    padding-bottom: 250px;
}

.contact .contact_block {
    width: 100%;
    min-height: 0;
}

.contact .contact_head {
    width: 100%;
    display: flex;
    overflow-y: hidden;
}

.contact .contact_head .btn {
    padding: 18px 45px;
    font-size: 18px;
    color: var(--black);
    transition: .2s ease;
    cursor: pointer;
    border-radius: 5px 5px 0 0;
    border-bottom: 2px solid var(--3, #DBDBDB);
}

.contact .contact_head .btn.active {
    font-weight: 400;
    color: var(--blue);
    border-bottom: 2px solid var(--blue);
}

.contact .contact_body {
    margin-top: 40px;
    border-radius: 0 0 5px 5px;
}

.contact .h1 {
    font-size: 25px;
    font-weight: 400;
    color: var(--black);
}

.contact .contact_box {
    display: flex;
    align-items: center;
    gap: 15px;
}

.contact .contant {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.contact .contant.w {
    margin-top: 30px;
}

.contact .contact_box .h3 {
    font-size: 18px;
    font-weight: 500;
    color: var(--black);
}

.contact .contact_box p {
    font-size: 15px;
    font-weight: 300;
    color: var(--black);
}

.contact .contact_box a {
    font-size: 15px;
    font-weight: 300;
    color: var(--blue);
    text-decoration: none;
}

.contact .contact_box .box {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.contact .icon {
    width: 24px;
    height: 24px;
}

.contact .map {
    margin-top: 40px;
    width: 100%;
    height: 400px;
}

.contact .map_google {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 30px;
}

@media (max-width: 600px) {
    .contact {
        padding-bottom: 150px;
    }

    .contact .contact_head .btn {
        font-size: 14px;
        padding: 10px 20px;
    }

    .contact .h1 {
        display: none;
    }

    .contact .contact_box .h3 {
        font-size: 15px;
    }

    .contact .contact_box p,
    .contact .contact_box a {
        font-size: 14px;
    }

    .contact .map {
        margin-top: 25px;
        height: 200px;
    }

    .contact .contant {
        margin-top: 40px;
    }
}