.label_form {
  color: var(--black);
  font-size: 14px;
  font-weight: 400;
}

.input_form {
  margin-top: 8px;
  width: 100%;
  height: 50px;
  background: var(--grayW);
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 300;
  color: var(--black);
  border: 1px solid var(--grayW);
  border-radius: 10px;
  padding: 10px 20px;
}

.input_form::placeholder {
  color: var(--gray);
}

.input_form:focus {
  border: 1px solid var(--blue);
}

.input_select {
  margin-top: 5px;
  width: 100%;
  height: 40px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  padding: 0;
  border: none;
  outline: none;
  color: #081f2c;
  background: #fdfdfd;
  border: 1px solid var(--blue);
  border-radius: 3px;
  padding: 0 4px;
}

.button_form {
  margin-top: 8px;
  outline: none;
  border: none;
  width: 100%;
  height: 45px;
  border-radius: 10px;
  background: var(--blue);
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
  color: #fdfdfd;
  font-size: 15px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}

.input_box {
  width: 100%;
  height: 90px;
  position: relative;
}

.span-icon {
  z-index: 1;
  position: absolute;
  top: 18px;
  right: 18px;
  font-size: 20px;
  color: var(--gray);
  cursor: pointer;
}

.login_form .h1 {
  font-size: 24px;
  font-weight: 400;
  color: var(--black);
}

.auth {
  padding-bottom: 100px;
}

.login_form {
  margin: 5% auto;
  max-width: 432px;
  width: 100%;
  background: var(--white);
  box-shadow: 0 1px 8px rgba(10, 10, 15, 0.15);
  border-radius: 30px;
}

.login_form_head {
  width: 100%;
  padding: 30px;
  display: flex;
  justify-content: center;
  padding-bottom: 0;
}

.login_form_body {
  width: 100%;
  padding: 30px;
}

.forgot_password_link {
  display: flex;
  justify-content: flex-end;
}

.forgot {
  /* margin-left: auto; */
  color: var(--blue);
  font-size: 14px;
  font-weight: 300;
  text-decoration: none;
}

.p_content {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  color: var(--gray);
  text-align: center;
}

.a_content {
  color: var(--blue);
  font-size: 15px;
  font-weight: 300;
  text-decoration: none;
}

.red {
  text-align: end;
  font-weight: 400;
  font-size: 13px;
  color: red;
}

.navigate {
  margin-top: 30px;
  width: 100%;
}

.navigate img {
  cursor: pointer;
}

@media (max-width: 600px) {
  .input_form {
    font-size: 14px;
  }

  .input_select {
    font-size: 14px;
  }

  .button_form {
    font-size: 14px;
  }

  .login_form .h1 {
    font-size: 18px;
  }

  .auth {
    padding-top: 20px;
    padding-bottom: 150px;
    background: var(--white);
  }

  .login_form {
    margin: 20 auto;
    max-width: 400px;
    width: 100%;
    box-shadow: none;
  }

  .login_form_head {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    padding-bottom: 0;
  }

  .login_form_body {
    width: 100%;
    padding: 0;
    padding-top: 20px;
  }

  .forgot_password_link {
    display: flex;
    justify-content: flex-end;
  }

  .forgot {
    /* margin-left: auto; */
    color: var(--blue);
    font-size: 14px;
    font-weight: 300;
    text-decoration: none;
  }

  .p_content {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    color: var(--gray);
    text-align: center;
  }

  .a_content {
    color: var(--blue);
    font-size: 15px;
    font-weight: 300;
    text-decoration: none;
  }

  .red {
    text-align: end;
    font-weight: 400;
    font-size: 13px;
    color: red;
  }

  .navigate {
    margin-top: 0;
    width: 100%;
  }
}
