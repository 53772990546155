.about {
    width: 100%;
    background: var(--white);
    padding-bottom: 250px;
}

.about h2 {
    font-size: 30px;
    font-weight: 400;
    color: var(--black);
}






@media (max-width: 600px) {
    .about h2 {
        font-size: 18px;
        font-weight: 400;
        color: var(--black);
    }

    .about {
        padding-bottom: 150px;
    }
}