.about_banner {
    padding-top: 50px;
    width: 100%;
}

.about_banner img {
    width: 100%;
    height: 400px;
    border-radius: 30px;
    object-fit: cover;
    object-position: center;
}

@media (max-width: 600px) {
    .about_banner {
        padding-top: 16px;
    }

    .about_banner img {
        width: 100%;
        height: 160px;
        border-radius: 16px;
        object-fit: cover;
        object-position: center;
    }
}