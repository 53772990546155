.search {
  width: 100%;
  position: relative;
}

.search .slider {
  width: 100%;
  height: 400px;
  display: flex;

  justify-content: center;
}

.search .slider_slick {
  width: 100%;
  padding-top: 25px;
  padding-bottom: 100px;
}

.img_slider-search {
  margin: 0 auto;
  width: 100px;
  border-radius: 0px;
}

.search .search_block {
  position: absolute;
  bottom: -60px;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  max-width: 1180px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 36px 30px;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.08) !important;
  z-index: 9999;
}

.search .search_div {
  width: 100%;
  /* display: grid;
  grid-template-columns: 2.5fr 1fr 1fr 1fr; */
  display: flex;
  align-items: center;
  gap: 10px;
}

.search .search_div_in {
  display: grid;
  grid-template-columns: 1fr 40px 1fr;
}

.search .label_form {
  font-size: 14px;
  font-weight: 400;
  color: var(--gray);
}

.search .search_div_in input {
  width: 200px;
  font-size: 20px;
}

.search .relative {
  width: 100%;
  position: relative;
}

.search input {
  margin-top: 4px;
  padding: 0 8px;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: 300;
  outline: none;
  border: none;
  border: 1px solid #ccc;
}

.search input::placeholder {
  color: #9f9f9f;
}

.search input:hover {
  border: 1px solid var(--blue);
}

.search input:focus {
  border: 1px solid var(--blue);
  box-shadow: 0 0 5px rgb(0, 78, 208);
}

.search .btn_box {
  margin-top: 20px;
  margin-right: 25px;
  height: 3.5rem;
  padding: 0.75rem 0.375rem 0.75rem 0.875rem;
  background: #eeeeee;
  border-radius: 5px 0 0 5px;
  position: relative;
  cursor: pointer;
}

.search .btn_box::before {
  content: "";
  position: absolute;
  top: 8px;
  right: -21px;
  width: 40px;
  height: 40px;
  background: #eeeeee;
  z-index: 1;
  transform: rotate(45deg);
}

.search .buttons {
  position: static;
  z-index: 1;
  width: 140px;
  height: 100%;
  background: var(--orange);
  border-radius: 4px 0 0 4px;
  color: var(--white);
  font-size: 15px;
  font-weight: 400;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0.3em 0.375rem 0.3em;
  position: relative;
}

.search .buttons::before {
  content: "";
  position: absolute;
  top: 5px;
  right: -12px;
  width: 23px;
  height: 22px;
  background: var(--orange);
  z-index: 1;
  transform: rotate(45deg);
}

.search .menu_search {
  display: none;
}

.search .menu_search .search_title {
  margin: 0 auto;
  text-align: center;
  font-size: 20px;
  width: 250px;
  font-weight: 500;
  color: var(--white);
}

.search .slide_div_slider {
  outline: none;
  border: none;
}

.search .slide_div_slider .slider {
  outline: none;
  border: none;
}

.search .arrow {
  margin-top: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  cursor: pointer;
}

.search .arrow .icons {
  font-size: 24px;
  color: var(--gray);
}

.search .arrow:hover {
  background: var(--blue);
}

.search .arrow:hover .icons {
  color: var(--white);
}

.search .relative_in {
  position: relative;
}

.search .relative_in .gray_ab {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 10px;
  font-size: 16px;
  font-weight: 400;
  color: var(--gray);
  display: flex;
  align-items: center;
  gap: 5px;
}

.search .relative_in .gray_ab .icon {
  font-size: 15px;
  color: var(--gray);
}

.search .search_out {
  display: flex;
  flex-direction: column;
}

.search .grid {
  display: flex;
  gap: 10px;
}

.search .border2 {
  cursor: pointer;
  background: transparent;
}

.search .icon_arrow {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  font-size: 15px;
  color: #9f9f9f;
  cursor: pointer;
  transition: 0.3s ease;
}

.search .name {
  font-size: 17px;
  font-weight: 400;
  color: var(--black);
}

.search .name span {
  margin-left: 5px;
  font-size: 14px;
  font-weight: 300;
  color: var(--black);
}

.search .icon_arrow.active {
  transform: rotate(180deg);
}

.search .relative_pass_div {
  display: flex;
  flex-direction: column;
}

.search .relative_pass {
  position: relative;
}

.search .box_deg {
  width: 30px;
  height: 30px;
  transform: rotate(45deg);
  position: absolute;
  top: -16px;
  left: 0;
  right: 0;
  margin: auto;
  background: white;
  border-top: 1px solid #dddddd;
  border-left: 1px solid hsl(0, 0%, 87%);
}

.search .absolute_pass {
  position: absolute;
  z-index: 999999999;
  top: 26px;
  left: -100%;
  right: -100%;
  width: 300%;
  padding: 20px;
  border-radius: 24px;
  background: var(--white);
  box-shadow: 5px 5px 25px rgba(222, 222, 222, 0.5),
    0px 5px 20px 0px rgba(194, 194, 194, 0.09),
    0px 10px 6px 0px rgba(61, 61, 63, 0.05),
    0px 19px 7px 0px rgba(56, 55, 55, 0.01), 0px 29px 8px 0px rgba(0, 0, 0, 0);
  border: 1px solid #dddddd;
  animation: selected 0.4s ease;
  padding-right: 30%;
}

@keyframes selected {
  0% {
    opacity: 0;
    top: 100px;
  }
  100% {
    opacity: 1;
    top: 26px;
  }
}

.search .closes {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 20px;
  color: #9f9f9f;
}

.search .array {
  margin: 8px 0;
  display: flex;
  align-items: center;
  border-left: 1px solid #bbbbbb;
  border-radius: 0.25rem 0 0 0.25rem;
}

.search .array_box {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #bbbbbb;
  font-size: 15px;
  font-weight: 500;
  border-left: none;
  font-size: 15px;
  font-weight: 300;
  color: #666666;
}

.search .array_box:first-child {
  border-radius: 0.25rem 0 0 0.25rem;
}

.search .array_box:last-child {
  border-radius: 0 0.25rem 0.25rem 0;
}

.search .array_box.active {
  color: var(--white);
  font-weight: 400;
  background: var(--blue);
}

@media (max-width: 600px) {
  .search .absolute_pass {
    width: 100%;
    left: 0;
    right: 0;
    padding: 10px;
    padding-top: 30px;
  }
  .search .search_block {
    width: 90%;
  }

  .search .menu_search {
    width: 100%;
    height: 75vh;
    padding-top: 50px;
    background: var(--blue);
    display: block;
  }

  .search .search_block {
    top: 350px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 24px 16px;
  }

  .search .search_block input {
    width: 100%;
    padding: 10px;
    height: 45px;
    font-size: 15px;
    border-radius: 12px;
  }

  .search .slider_slick {
    padding-top: 25px;
    padding-bottom: 0;
  }

  .search .slider {
    height: 200px;
  }

  .search .search_div {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .search .search_div_in {
    gap: 10px;
  }

  .search .slider img {
    border-radius: 16px;
  }

  .search button {
    width: 100%;
  }

  .search .search_div_in {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }

  .search .relative_in,
  .search .search_div_in input {
    width: 100%;
  }

  .search .grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-bottom: 10px;
  }

  .search .arrow {
    display: none;
  }

  .search .as {
    width: 100%;
  }

  .search .btn_box {
    width: calc(100% - 32px);
  }
  /* 
  .search .btn_box::before {
    height: 45px;
    right: -20px;
  } */

  .search .buttons {
    width: 100%;
  }

  .search .search_out {
    width: 100%;
    margin-bottom: 10px;
  }
}

.slick-next {
  right: 25px !important;
}

.slick-prev {
  left: 25px !important;
  z-index: 1 !important;
}

.slick-prev,
.slick-next {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.slick-slider:hover .slick-prev,
.slick-slider:hover .slick-next {
  opacity: 1 !important;
  cursor: pointer;
}

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 40px !important;
  height: 40px !important;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: #fff;
  border: none;
  outline: none;
  background: #ffffff;
}

.slick-next:hover {
  background: #ffffff;
}

.slick-prev:hover {
  background: #ffffff;
}

.slick-next:before {
  content: "";
  display: inline-block;
  width: 28px;
  height: 28px;
  background-image: url("../../img/2.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.slick-prev:before {
  content: "";
  display: inline-block;
  width: 28px;
  height: 28px;
  background-image: url("../../img/2.png");
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(180deg);
}
